import React from "react"

class LearningLink extends React.Component {

  constructor( props ) {
    super( props );
    this.show = this.show.bind( this );
    this.hide = this.hide.bind( this );
    this.toggleExplanation = this.toggleExplanation.bind( this );
    this.makeid = this.makeid.bind( this );
    this.state = { canSee: false, linkId: this.makeid()};
  }

  makeid() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }


  show() {
    this.setState( { canSee: true } );
    let elem = document.getElementById(this.state.linkId);
    elem.style.display = 'block'
  }

  hide() {
    this.setState( { canSee: false } );
    let elem = document.getElementById(this.state.linkId);
    elem.style.display = 'none'
  }

  toggleExplanation() {
    if ( this.state.canSee === true ) {
      this.hide();
    } else {
      this.show();
    }

  }

  render() {
    return (
      <div className='learninglink-cont'>
        <div className=""
             style={{ color: "#1D6CD3", cursor: "pointer", fontSize: "14px" }}
             onClick={this.toggleExplanation}>
          {this.props.promptString}
        </div>

        <p className=""
           id={this.state.linkId}
           style={{ fontSize: "14px", display: "none" }}>
          {this.props.explanationString}
        </p>
      </div>

    );
  }

}

export default LearningLink;
