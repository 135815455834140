import React from "react"
import PropTypes from "prop-types"

class DonationSelector extends React.Component {

  constructor(props) {
      super(props);
      console.log(this.props)
      this.donationAmountSelected = this.donationAmountSelected.bind(this);
      this.customDonationChanged = this.customDonationChanged.bind(this);
      this.validateDonationAmount = this.validateDonationAmount.bind(this);
      this.state = { selectedTierAmount: null, customSelected: false};
  }

  customDonationChanged(e) {
    let value = document.getElementById("custom_donation_amount").value;
    if (value != ""){
      this.setState( { selectedTierAmount: value });
      this.setState( { customSelected: true });
    }
  }

  validateDonationAmount(e){

    var sanitizedAmount = 0;
    if (this.state.selectedTierAmount != null){
        sanitizedAmount = this.state.selectedTierAmount.replace("$", "").replace(",", "");
        sanitizedAmount = parseInt(sanitizedAmount);
    }
    var minimumDonation = parseInt(this.props.minimumDonationAmount);

    if (sanitizedAmount < minimumDonation){
      alert("Minimum donation for this fundraiser is $"+ this.props.minimumDonationAmount)
    }
    else {

      var newLocation = "/donate/" + this.props.fundraiserSlug + "/payments/new?amount=" + sanitizedAmount;
      if (this.props.selectedDonor != null) {
        newLocation = newLocation + "&c=" + this.props.selectedDonor;
      }
      window.location.href = newLocation
    }

    e.preventDefault();
    return false;
  }

  donationAmountSelected(tierAmount) {
    this.setState( { selectedTierAmount: tierAmount });
    this.setState( { customSelected: false });
  }

  render () {
    let tierRows = [];
    let me = this;
    var sanitizedAmount = 0;
    if (this.state.selectedTierAmount != null){
        sanitizedAmount = this.state.selectedTierAmount.replace("$", "").replace(",", "");
        // sanitizedAmount = parseInt(sanitizedAmount) * 100;
    }
    var minimumDonation = parseInt(this.props.minimumDonationAmount);
      this.props.tiers.map(function(tier_amount, i) {
        tierRows.push(
          <div>
          { tier_amount == me.state.selectedTierAmount &&
          <div className="col l4 s12" key={i}  onClick={() => me.donationAmountSelected(tier_amount)}  >
             <div className="donation-tier-box selected">
                <div>
                  {tier_amount}
                </div>
                <div className='custom-text'>
                </div>
             </div>
          </div>
          }
          { tier_amount != me.state.selectedTierAmount &&
          <div className="col l4 s12" key={i}  onClick={() => me.donationAmountSelected(tier_amount)}  >
             <div className="donation-tier-box">
                <div>
                  {tier_amount}
                </div>
                <div className='custom-text'>
                </div>
             </div>
          </div>
          }
          </div>
        );});
    return (
      <div>
        <div className="row">
         {tierRows}
        <div className="col l4 s12">
          <div onClick={this.customDonationChanged } type className={this.state.customSelected ? "donation-tier-box selected" : "donation-tier-box"}   >
            <div className='flex-form-element'>
             <span>$</span>
              <input onChange={this.customDonationChanged } type="number" max="4" name="custom_donation_amount" id="custom_donation_amount" className="search" placeholder="0.00" />
             </div>
             <div className='custom-text'>
                {"Custom Amount, $" + minimumDonation.toString() + " min."}
             </div>
          </div>
         </div>
      </div>
      <div className="row">
        <div className="col l12 s12 wizard-buttons-cont">
          <a className="waves-effect waves-light white-text grey btn-large" href={"/donate/" + this.props.fundraiserSlug } >Cancel</a>
          {this.state.selectedTierAmount == null &&
            <a className="waves-effect waves-light white-text blue btn-large" disabled="disabled" >Next</a>
          }
          {this.state.selectedTierAmount != null &&
            <a href='#' className="waves-effect waves-light white-text blue btn-large" onClick={this.validateDonationAmount} >Next</a>
          }
        </div>
      </div>
    </div>
    );
  }
}
export default DonationSelector;
