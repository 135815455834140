/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import { mountComponents } from 'react-rails-ujs';
//
// import { EditCoverImage } from '../components/EditCoverImage';
// import { EditPrizeImage } from '../components/EditPrizeImage';
// import { EditFundraierThumb } from '../components/EditFundraiserThumb';
//
// mountComponents({
//     EditCoverImage,
//     EditPrizeImage,
//     EditFundraierThumb
// });

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
// require("babel-core/register");
// require("babel-polyfill");
ReactRailsUJS.useContext(componentRequireContext)
