import {
  TwitterShareButton, TwitterIcon
} from 'react-share';
import React from "react"
import PropTypes from "prop-types"

class TwitterShare extends React.Component {
  render() {
    return (
      <div id={this.props.id}>
        <TwitterShareButton
          url={this.props.url}
          title={this.props.title}
          via={this.props.via}
          className={"twitter-share"}>
          <img src={this.props.thumbUrl}/>
          {this.props.displayText &&
            <div className='share-text pad-top-10'>
              Post to <br /> Twitter
            </div>
          }
        </TwitterShareButton>
      </div>
    );
  }
}

TwitterShare.defaultProps = {
  displayText: true
}

TwitterShare.propTypes = {
  quote: PropTypes.string,
  displayText: PropTypes.bool
};
export default TwitterShare
