import React from "react"
import PropTypes from "prop-types"
import { Line, Circle } from 'rc-progress';
// import 'rc-progress/assets/index.css';
class ProgressBar extends React.Component {
  render () {
    var percent = this.props.percent;
    if (percent > 100){
      percent = 100;
    }
    return (
      <div>
        <Line strokeLinecap={this.props.strokeLinecap} trailWidth={this.props.strokeWidth} percent={percent} strokeWidth={this.props.strokeWidth} strokeColor={this.props.strokeColor} />
      </div>
    );
  }
}

ProgressBar.defaultProps = {
  strokeWidth: 4,
  strokeLinecap: "round"
}

ProgressBar.propTypes = {
  greeting: PropTypes.string,
  strokeWidth: PropTypes.number,
  strokeLinecap: PropTypes.string
};

export default ProgressBar
