import {
  FacebookShareButton,FacebookIcon
} from 'react-share';
import React from "react"
import PropTypes from "prop-types"
class FacebookShare extends React.Component {
  render () {
    return (
      <div id={this.props.id}>
        <FacebookShareButton
          url={this.props.url}
          quote={this.props.quote}
          className={"facebook-share"}>
            <img src={this.props.thumbUrl} />
            {this.props.displayText &&
              <div className='share-text pad-top-10'>
              Post to <br /> Facebook
              </div>
            }
        </FacebookShareButton>
      </div>
    );
  }
}

FacebookShare.defaultProps = {
  displayText: true
}

FacebookShare.propTypes = {
  quote: PropTypes.string,
  displayText: PropTypes.bool
};
export default FacebookShare
