import React from "react"
import MaskedTextInput from "react-text-mask"

class PhoneInput extends React.Component {
  render() {
    return (
      <div>
        <MaskedTextInput
          mask={[ '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/ ]}
          className="form-control"
          placeholder="(555) 123-1234"
          name="phone_number"
          guide={false}
          id="phone_number"
          required={true}
          pattern={".{14,14}"}
          type={'tel'}
        />
      </div>
    );
  }
}

export default PhoneInput
