import React from "react"
import Rodal from 'rodal';

class WizardSubmitButton extends React.Component {

  constructor(){
    super();
    this.handleClick = this.handleClick.bind( this );
    this.show = this.show.bind( this );
    this.submitAnyway = this.submitAnyway.bind( this );
    this.hide = this.hide.bind( this );
    this.state = { canSee: false };
  }

  handleClick(e){
    var submitForm = true;
    var self = this;
    this.props.validations.map(function(validationAndArgs, i) {
      var validationFunc = eval(validationAndArgs[0]);
      var args = validationAndArgs.slice(1).map(function(argName, i) {
        return eval("self.props." + argName);
      });
      if (!validationFunc.apply(null, args)) {
        self.show();
        submitForm = false;
        return;
      }
    });

    if (submitForm){
      document.getElementsByClassName(this.props.formClass)[0].submit();
    }
  }

  show() {
    this.setState( { canSee: true } );
  }

  hide() {
    this.setState( { canSee: false } );
  }

  submitAnyway(){
    document.getElementsByClassName(this.props.formClass)[0].submit();
  }

  render(){
    return (
      <div>
        <div className='save-button-cont' >
          <Rodal
            width={400}
            height={210}
            style={{ marginTop: 120 }}
            onClose={this.hide}
            closeOnEsc={true}
            visible={this.state.canSee}>

            <div style={{ width: "90%", height: 400 }}>
              <div className='popup-header' >
                Required Items haven't been filled out or are invalid.
              </div>
              <div className='popup-content' style={{ marginTop: 10 }} >
                You can skip and continue building your fundraiser,
                but you won't be able to launch until all required fields are complete and valid.
              </div>
              <div style={{marginTop: 24}}>
                <div className='wizard-back-button' onClick={this.hide} >
                  Back
                </div>
                <a onClick={this.submitAnyway} className='wizard-skip-button' >
                  SKIP
                </a>
              </div>
            </div>
          </Rodal>
          <a onClick={this.handleClick} className="waves-effect white-text blue wide-button donate-button waves-input-wrapper link-padding" >{this.props.buttonText}</a>
        </div>
      </div>
    )
  }

}
export default WizardSubmitButton
