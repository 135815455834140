import React from "react"
import Steps, { Step } from 'rc-steps';

class CreationStepper extends React.Component {
  render() {
  return (
    <div>
      <Steps current={this.props.currentStep}>
        <Step title="">
          My Step
        </Step>
        <Step title="" />
        <Step title="" />
        <Step title="" />
      </Steps>
    </div>
  );
}
}

export default CreationStepper;
