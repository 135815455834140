import React from "react"
import PropTypes from "prop-types"
import Joyride from 'react-joyride';


class SimpleTooltip extends React.Component {

  render() {

    return (
      <div >
      <Joyride
          ref="joyride"
          steps={[]}
          run={true}
          debug={false}
          />
      </div>
    );
  }
}

export default SimpleTooltip
