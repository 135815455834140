if (!fetch) require('whatwg-fetch');
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageCropper from './ImageCropper';
import Croppie from 'croppie';
import axios from 'axios';

export default class EditCoverImage extends Component {

  constructor(props){
    super(props)
    this.uploadToS3 = this.uploadToS3.bind(this);
  }

  uploadToS3(file, fileName) {
    const csrfToken = document.getElementsByName("csrf-token")[0].content
    const data = {
      key: 'fileName',
      fundraiser_id: this.props.fundraiserId,
      'content-type': file.type,
      'X-CSRF-Token': csrfToken,
      file
    };
    const body = new FormData();
    _.each(data, (item, key) => {
      body.append(key, item);
    });
    var self = this;
    const baseLocation = this.props.uploadURI;
    return axios({
      method: 'post',
      url: baseLocation,
      data: body,
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    })
    .then(function (response) {
      //handle success
      $("#" + self.props.loadedid).val("true")
      $("#" + self.props.loadedid).next().children().addClass("hidden")
      return response;
    })
    .catch(function (response) {
      //handle error
      alert("There was a problem uploading your image.")
    });

  }

  render() {
    return (<ImageCropper
      actualSize={this.props.actualSize}
      uploadText={this.props.uploadText}
      onUploaded={this.uploadToS3}
      backgroundImage={this.props.src}
      mobileHeightCompensation={30}
      width={132}
      height={132}
      displayWidth={132}
      displayHeight={132}
      croppedWidth={132}
      croppedHeight={132} />);
  }

}
