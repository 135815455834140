import React from "react"
import PropTypes from "prop-types"
import YouTube from 'react-youtube';
import LearningLink from "./LearningLink";


class YoutubeFormLink extends React.Component {

  constructor(){
    super();
    this.getVideoId = this.getVideoId.bind( this );
    this.urlChanged = this.urlChanged.bind( this );
  }

  urlChanged(e){
    let value = document.getElementById(this.props.inputID).value;
    let newId = this.getVideoId(value);
    this.setState({
      videoURL: value
     });
    if (newId){
      this.setState({
        videoID: newId
       });
    }
    else if (value === ''){
      this.setState({
        videoID: ''
       });
    }
  }

  componentWillMount(){
    console.log(this.props.videoURL);
    this.setState({ videoURL: this.props.videoURL,
      videoID: this.getVideoId(this.props.videoURL) })
    // this.setState({
    //
    // });
  }


  getVideoId(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
    }


  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };

    var renderedVideo;
    if (this.state && this.state.videoID){
      renderedVideo = <YouTube
        videoId={this.state && this.state.videoID}
        opts={opts}
      />
    }
    else {
      renderedVideo = <div><img className='youtube-image' src={this.props.placeholderImage} />  </div>
    }
    return (
      <div >
        <label htmlFor="fundraiser_video_url" className="active">{this.props.label}</label>
        <input placeholder="Paste Youtube link here" value={this.state.videoURL}
        onChange={(evt) => this.urlChanged(evt)}
        type="text"
        name={this.props.inputName}
        id={this.props.inputID} />
        <LearningLink promptString="Why should I share a video?"
          explanationString="Having a simple video helps donors empathize and relate to your cause, increasing the likelihood of donations. You can film yourself, your group, or your team talking about why you are fundraising. Upload it to YouTube, then copy the link here to post it to your page." />
        {renderedVideo}
      </div>
    );
  }
}

export default YoutubeFormLink
