import React from 'react';
import ReactDOM from 'react-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';

class CopyToShare extends React.Component {
  state = {
    copied: false,
  };
  render(){
    return (
    <div id={this.props.id}>
        <CopyToClipboard text={this.props.copyThis}
          onCopy={() => this.setState({copied: true})}>
          <div className={'copy-to-share'}>
            <img src={this.props.thumbUrl} className="copy-to-share-svg" />
            {!this.state.copied &&
              <div className="copy-to-share-text">
                Copy link
              </div>
            }
            {this.state.copied &&
              <div className="copy-to-share-text">
                Copied!
              </div>
            }
          </div>
        </CopyToClipboard>
      </div>
    );
  }
}

export default CopyToShare;
